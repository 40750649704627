/** @format */

import { inject, bindable, computedFrom } from 'aurelia-framework';

export class DeviceFeatures {
  @bindable features;
  @bindable deviceFeatures;
  @bindable dvrCheck;

  constructor() {
    //
    this.featuresList = [];
  }

  featuresChanged() {
    this.refreshFeatures();
    this.updateDeviceFeatures();
  }
  
  deviceFeaturesChanged() {
    this.refreshFeatures();
  }

  onFeatureChecked(e, feature) {
    this.updateDeviceFeatures();
  }

  refreshFeatures() {
    this.featuresList = (this.features || []).map(f => {
      return {
        checked: !!(this.deviceFeatures || []).find(df => df.id === f.id),
        id: f.id,
      };
    });
  }
  
  updateDeviceFeatures() {
    this.deviceFeatures = this.featuresList.filter(x => x.checked).map(x => {
      return {
        id: x.id,
      };
    });

    this.dvrCheck = this.deviceFeatures.some(i => i.id == 'dvr')
  }
}
