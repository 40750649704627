/** @format */

import {
  inject,
  computedFrom,
  bindable,
  DOM
} from 'aurelia-framework';
import 'chartist/dist/chartist.css';

@inject(DOM.Element)
export class Chartist {
  @bindable type;
  @bindable data;
  @bindable options;
  @bindable responsiveOptions;
  @bindable ratios;
  @bindable height;

  @bindable onPointClick;
  @bindable onPointMouseover;
  @bindable onPointMouseout;
  @bindable onChartDraw;

  constructor(DOMEl) {
    this.Chartist = require('chartist');
    this.chartObj = null;

    this.Tooltip = require('chartist-plugin-tooltips');

    this.DOMEl = DOMEl;

    this.pointClick = this.pointClick.bind(this);
    this.pointMouseover = this.pointMouseover.bind(this);
    this.pointMouseout = this.pointMouseout.bind(this);
  }

  @computedFrom('height')
  get cssStyles() {
    return {
      height: this.height ? this.height : '100%'
    };
  }

  get chartType() {
    let _type = this.type || '';
    return _type.charAt(0).toUpperCase() + _type.slice(1);
  }

  //note: aurelia ref seems to be null when using multiple instances of the same custom component...
  get chartElem() {
    return this._chartElem;
  }

  get pointElements() {
    if (this.chartElem) {
      return Array.prototype.slice.call(
        this.chartElem.querySelectorAll('.ct-series')
      );
    }
  }

  get svgMetaAttribute() {
    return 'ct:meta';
  }

  attached() {
    this._chartElem = this.DOMEl.querySelector('.ct-chart');
    this.render();
  }

  detached() {
    if (this.chartObj) {
      this.removeListeners();
      this.chartObj.detach();
    }
  }

  dataChanged() {
    this.render();
  }

  render() {
    let ChartType = this.Chartist[this.chartType];

    if (!ChartType) {
      this.error(`Chart type is not valid! [${ChartType}]`);
    }

    if (this.options && this.options.plugins && this.options.plugins.length) {
      if (this.options.plugins.includes('tooltip')) {
        this.options.plugins.splice('tooltip', 1);
        this.options.plugins.push(this.Chartist.plugins.tooltip({}));
      }
    }

    if (this.chartElem && this.data !== null) {
      if (!this.chartObj) {
        this.chartObj = new ChartType(
          this.chartElem,
          this.data || {},
          this.options,
          this.responsiveOptions
        );
        this.chartObj.on('created', this.attachListeners.bind(this));

        if (this.onChartDraw) {
          this.chartObj.on('draw', this.onChartDraw);
        }
      } else {
        this.chartObj.update(this.data, this.options, this.responsiveOptions);
        //update
      }
    }
  }

  removeListeners() {
    this.pointElements.forEach(point => {
      point.removeEventListener('click', this.pointClick);
      point.removeEventListener('mouseover', this.pointMouseover);
      point.removeEventListener('mouseout', this.pointMouseout);
    });
  }

  attachListeners() {
    this.pointElements.forEach(point => {
      if (this.onPointClick) {
        point.addEventListener('click', this.pointClick);
      }
      if (this.pointMouseover) {
        point.addEventListener('mouseover', this.pointMouseover);
      }
      if (this.pointMouseout) {
        point.addEventListener('mouseout', this.pointMouseout);
      }
    });
  }

  pointClick(e) {
    let target = e.target;
    if (this.onPointClick && target) {
      let meta = target.getAttribute(this.svgMetaAttribute);
      this.onPointClick(e, meta);
    }
  }

  pointMouseover(e) {
    let target = e.target;
    if (this.onPointMouseover && target) {
      this.onPointMouseover(e);
    } else if (target) {
      if (target.parentElement && target.parentElement.classList) {
        if (target.parentElement.classList[1]) {
          let seriesClass = target.parentElement.classList[1];
          let tooltipElem = this.chartElem.querySelectorAll('.chartist-tooltip')[0]
          if (tooltipElem) {
            tooltipElem.classList.add(seriesClass); 
          }
        }
      }
    }
  }

  pointMouseout(e) {
    let target = e.target;
    if (this.onPointMouseout && target) {
      this.onPointMouseout(e);
    } else if (target) {
      if (target.parentElement && target.parentElement.classList) {
        if (target.parentElement.classList[1]) {
          let seriesClass = target.parentElement.classList[1];
          let tooltipElem = this.chartElem.querySelectorAll('.chartist-tooltip')[0]
          if (tooltipElem) {
            tooltipElem.classList.remove(seriesClass); 
          }
        }
      }
    }
  }

  error(msg) {
    throw new Error(`aurelia-chartist: ${msg}`);
  }
}
