/** @format */

import { bindable, inject } from 'aurelia-framework';
import { IOSourceTypes, IOSources } from 'services/api/provisionService';
import { TranslationService } from 'services/translationService';
import Utils from 'utils/utils';

import './device-mappings.scss';

const IOSource_DEFAULT = {
  id: -1,
  name: 'none',
  default: true
};

const IOType_DEFAULT = {
  id: -1,
  name: 'none',
  default: true
};

@inject(TranslationService)
export class DeviceMappings {
  @bindable ioMappings;
  @bindable disabled;
  @bindable readonly;
  @bindable onMappingsChanged;

  /**
   * This component is a custom input to edit device mappings
   * @param {TranslationService}
   */
  constructor(_TranslationService) {
    //DI injecion
    this.translations = _TranslationService;

    //Default props
    this.disabled = false;
    this.readonly = false;

    //Members
    this._ioMappings = [];

    this._ioSources = [IOSource_DEFAULT].concat(
      IOSources.map(Utils.strToItemObj)
    );

    //client side bulls*@t bussiness rules
    //some iosources are multiple selections
    const toIoSource = io => {
      return Object.assign(Utils.strToItemObj(io), {
        multiple:
          io === 'doorsensor' || io === 'temperaturesensor' || io === 'generic'
      });
    };

    this._ioSourceTypes = [IOType_DEFAULT].concat(
      IOSourceTypes.map(toIoSource)
    );

    //Events
    this.onPortChanged = this.onPortChanged.bind(this);
    this.onTypeChanged = this.onTypeChanged.bind(this);
    this.onIoNameChanged = this.onIoNameChanged.bind(this);
    this.onMappingDelete = this.onMappingDelete.bind(this);
  }

  /**
   * Event - Fires when device ioMappings binding changes
   * It builds ioMappings array to use as source of data for UI list
   *
   * @param {Array} value - Array of ioMapping objects
   */
  ioMappingsChanged(value) {
    const mappings = (value || []).map(m => ({ item: m }));
    this._ioMappings = mappings;
    this.rebuildMappings();
  }

  /**
   * Event - Fires when a ioMapping UI select-advanced value changes
   * Updates the ioMappings list state
   *
   * @param {Object} mapping - IoMapping list item (of _ioMappings prop)
   * @param {Object} value - IoSource selected value (of _ioSources prop)
   */
  onPortChanged(mapping, value) {
    mapping.item.sourceId = value.id;
    this.rebuildMappings();
  }

  /**
   * Event - Fires when a sourceType UI select-advanced value changes
   * Updates the ioMappings list state
   *
   * @param {Object} mapping - IoMapping list item (of _ioMappings prop)
   * @param {Object} value - sourceType selected value (of _ioSourceTypes prop)
   */
  onTypeChanged(mapping, value) {
    mapping.item.sourceType = value.id;

    //fire namechange since we changed the name
    mapping.item.name =
      (!value.default && this.translations.getCap(value.name)) || '';
    this.onIoNameChanged(null, mapping);

    this.rebuildMappings();
  }

  /**
   * Event - Fires when a IoMapping item name is changed
   *
   * @param {Event} event
   * @param {Object} mapping - IoMapping object
   */
  onIoNameChanged(event, mapping) {
    if (mapping.isLast) {
      let nameLen = (mapping.item.name || '').length;
      if (!nameLen) return;

      mapping.isLast = false;
      mapping.dirty = true;
      this.appendNewItem();
      this.rebuildMappings();
    }

    if (!mapping.item.name.length) {
      this.removeMapping(mapping);
      return;
    }

    this.fireChangedEvent();
  }

  /**
   * Event - Fires on IoMapping delete 'click'
   * @param {Object} mapping - IoMapping object
   */
  onMappingDelete(mapping) {
    //can delete
    if (mapping.dirty && mapping.isLast) return;
    this.removeMapping(mapping);
  }

  /**
   * Proc - Rebuilds internal _ioMappings (for UI) list state
   */
  rebuildMappings() {
    const _ioMappings = this._ioMappings;

    if (_ioMappings) {
      const toUpdatedMapping = (m, idx) =>
      this.createMapping(m.item, idx, ...m);
      
      const mappings = _ioMappings.map(toUpdatedMapping);

      const needsNew = !mappings.length || !mappings[mappings.length - 1].dirty;

      this._ioMappings = mappings;

      if (needsNew) {
        this.appendNewItem();
      }

      //
      this.fireChangedEvent();
    }
  }

  /**
   * Func - Build list to populate UI select
   *
   * @param {Array} items - Array of source item objects
   * @param {Stirng} currentValue - current selected value
   * @param {string} currentValueField  - field of item object that holds currentValue
   * @returns {Array} - Array of select items data source
   */
  buildSelectList(items, currentValue, currentValueField) {
    return items.map(item => {
      let { id, name } = item;
      let selected = Utils.noCaseCmpStr(id, currentValue);
      let isDefault = item.default;
      let multiple = item.multiple;

      return {
        id,
        name,
        selected,
        default: isDefault,
        disabled:
          !selected &&
          !isDefault &&
          (!multiple && this.mappingsContains(currentValueField, id))
      };
    });
  }

  /**
   * Check if _ioMappings contains field with specific value
   * @param {string} field
   * @param {string} value
   * @returns {Booleans}
   */
  mappingsContains(field, value) {
    return !!this._ioMappings.find(m =>
      Utils.noCaseCmpStr(m.item[field], value)
    );
  }

  /**
   * Proc - Adds new item to _ioMappings
   */
  appendNewItem() {
    if (!this.disabled) {
      let len = this._ioMappings.length;
      const newItem = this.createMapping({ name: '' }, len, {
        isLast: true,
        dirty: true
      });

      this._ioMappings.push(newItem);
    }
  }

  /**
   * Func - Creates new Mapping object
   *
   * @param {object} item
   * @param {number} idx
   * @param {object} {dirty, isLast}
   */
  createMapping(item, idx, { dirty, isLast }) {
    const _ioSources = this._ioSources;
    const _ioSourceTypes = this._ioSourceTypes;

    const buildSelectList = this.buildSelectList.bind(this);

    return {
      item,
      idx,
      dirty,
      isLast,
      ports: buildSelectList(_ioSources, item.sourceId, 'sourceId'),
      sourceTypes: buildSelectList(
        _ioSourceTypes,
        item.sourceType,
        'sourceType'
      )
    };
  }

  /**
   * Removed an item from _ioMappings
   * @param {Object} mapping
   */
  removeMapping(mapping) {
    let idx = this._ioMappings.indexOf(mapping);
    this._ioMappings.splice(idx, 1);
    this.rebuildMappings();
  }

  /**
   * Event - Fires 'on-mappings-changed' event prop on this component
   * Fired anytime _ioMappings is updated
   */
  fireChangedEvent() {
    if (this.onMappingsChanged) {
      //filter items with no name or port selected
      let mappings = this._ioMappings
        .filter(x => !!x.item.name && !!x.item.sourceId)
        .map(m => m.item);

      // this.ioMappings = this._ioMappings;
      this.onMappingsChanged(mappings);
    }
  }
}
