/** @format */

import { bindable, inject } from 'aurelia-framework';
import { DVRIds } from 'services/api/provisionService';
import { TranslationService } from 'services/translationService';
import Utils from 'utils/utils';

import './device-dvr.scss';

const Channel_DEFAULT = {
  id: -1,
  name: 'none',
  default: true
};

@inject(TranslationService)
export class DeviceDvr {
  @bindable deviceFeatures;
  @bindable disabled;
  @bindable readonly;
  @bindable onDvrListChanged;

  /**
   * 
   * @param {TranslationService}
   */
  constructor(_TranslationService) {
    this.translations = _TranslationService;

    this.disabled = false;
    this.readonly = false;

    this.dvrList = [];

    this._dvrSourcesIds = [Channel_DEFAULT].concat(
      DVRIds.map(Utils.strToItemObj)
    );

    //Events
    this.onTypeChanged = this.onTypeChanged.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onNameChanged = this.onNameChanged.bind(this);
    this.onMappingDelete = this.onMappingDelete.bind(this);
  }

  onTypeChanged(dvr, value) {
    dvr.item.id = value.id;

    dvr.item.name =
      (!value.default && this.translations.getCap(value.name)) || '';
    dvr.item.name = this.translations.getCap('Channel ' + dvr.item.name);
    this.onNameChanged(null, dvr);

    this.rebuildList();
  }

  onNameChanged(event, dvr) {
    if (dvr && dvr.isLast) {
      let nameLen = (dvr.item.name || '').length;
      if (!nameLen) return;

      dvr.isLast = false;
      dvr.dirty = true;
      this.appendNewItem();
      this.rebuildList();
    }

    if (!dvr.item.name.length) {
      this.removeMapping(dvr);
      return;
    }

    this.fireChangedEvent();
  }

  onMappingDelete(dvr) {
    //can delete
    if (dvr.dirty && dvr.isLast) return;
    this.removeMapping(dvr);
  }

  removeMapping(dvr) {
    let idx = this.dvrList.indexOf(dvr);
    this.dvrList.splice(idx, 1);
    this.rebuildList();
  }

  onDelete(dvr) {
    //can delete
    if (dvr.dirty && dvr.isLast) return;
    this.removeMapping(dvr);
  }

  deviceFeaturesChanged(value) {
    !value ? value = [] : value;
    this.dvrList = value.map(m => ({ item: m }));
    this.rebuildList();
  }

  rebuildList() {
    const _list = this.dvrList;
    if (_list) {
      const toUpdatedMapping = (m, idx) =>
        this.createDvr(m.item, idx, ...m);

      const list = _list.map(toUpdatedMapping);
      const needsNew = !list.length || !list[list.length - 1].dirty;

      this.dvrList = list;
      if (needsNew) {
        this.appendNewItem();
      }

      //
      this.fireChangedEvent();
    }
  }

  appendNewItem() {
    if (!this.disabled) {
      let len = this.dvrList.length;
      const newItem = this.createDvr({ name: '' }, len, {
        isLast: true,
        dirty: true
      });

      this.dvrList.push(newItem);
    }
  }

  createDvr(item, idx, { dirty, isLast }) {
    const _dvrSourcesIds = this._dvrSourcesIds
    const buildSelectList = this.buildSelectList.bind(this);

    return {
      item,
      idx,
      dirty,
      isLast,
      id: buildSelectList(_dvrSourcesIds, item.id, 'id')
    };
  }

  fireChangedEvent() {
    if (this.onDvrListChanged) {
      let dvr = this.dvrList
        .filter(x => !!x.item.name && !!x.item.id)
        .map(m => m.item);

      this.onDvrListChanged(dvr);
    }
  }

  buildSelectList(items, currentValue, currentValueField) {
    return items.map(item => {
      let { id, name } = item;
      let selected = Utils.noCaseCmpStr(id, currentValue);
      let isDefault = item.default;
      let multiple = item.multiple;

      return {
        id,
        name,
        selected,
        default: isDefault,
        disabled:
          !selected &&
          !isDefault &&
          (!multiple && this.dvrContains(currentValueField, id))
      };
    });
  }

  dvrContains(field, value) {
    return !!this.dvrList.find(m =>
      Utils.noCaseCmpStr(m.item[field], value)
    );
  }

}
